import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../model/User';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceProviderService {

  public url = environment.baseUrl;
  public access_token: string = "";
  public refresh_token: string = "";
  public current_user: User;
  public isAffiliateAdmin:boolean=false;
  public loading;

  constructor(private http: HttpClient,public loadingController: LoadingController) {
    this.current_user = new User();
    if(localStorage.getItem("access_token")!=""){
      this.access_token=localStorage.getItem("access_token");
      this.getCurrentUser().subscribe(data=>{
        this.current_user.id=data["id"];
        this.current_user.email=data["email"];
        this.current_user.name=data["name"];      
        this.current_user.image=data["image"];      
      })
    }
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  async checkIfLoggedIn(router:Router,goto:string):Promise<boolean>{
    this.isAffiliateAdmin=false;
    if (this.access_token != "") {
      this.getCurrentUser().subscribe(response=>{
        console.log("logged in already!");
        console.log("checking isAffiliateAdmin!");
        this.checkIsAffiliateAdmin().subscribe(response=>{
          if(this.isAffiliateAdmin) {
            router.navigate([goto]);
            return true;  
          }
          else {
            console.log("NOT AffiliateAdmin!");
            router.navigate(['/login']);
            return false;            
          }
        })
      },
      error=>{
        console.log("not logged in, moving to login!")
        router.navigate(['/login']);
        return false;  
      })
    }
    else {
      console.log("not logged in, moving to login!")
      router.navigate(['/login']);
      return false;
    }
  }


  getCurrentUser() {
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.get(this.url + "/api/Users/Current", { headers: thisHttpHeader })
      .pipe(
        map(response => {
          console.log(response);
          return response;
        })
      );
  }


  processLogin(username, password) {
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'username': username, 'password': password });
    return this.http.post(this.url + "/auth/token", null, { headers: thisHttpHeader })
      .pipe(map(response => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      })
      );
  }

  redeemToken(refreshToken: string) {
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'token': refreshToken });
    return this.http.post(this.url + "/auth/redeem", null, { headers: thisHttpHeader })
      .pipe(map(response => {
        console.log(response);
        localStorage.setItem('access_token', response["access_token"]);
        localStorage.setItem('refresh_token', response["access_token"]);
        this.access_token = response["access_token"];
        this.refresh_token = response["refresh_token"];
        console.log(this.access_token);
        return response;
      })
      );
  }

  checkIsAffiliateAdmin(){
    var allAffiliates:any=[];
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.get(this.url + "/api/Affiliate", { headers: thisHttpHeader })
    .pipe(map(response => {
      allAffiliates=response;
      let affToManage = allAffiliates.filter(a => a.permissions.update);
      if(affToManage.length>0) {
        this.isAffiliateAdmin=true;
        this.current_user.managedAffiliates = affToManage;
      }
      else {
        this.isAffiliateAdmin=false;
        localStorage.clear();
      }
      return this.isAffiliateAdmin;
    })
    );
  }


  getAffiliates(){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.get(this.url + "/api/Affiliate", { headers: thisHttpHeader })
    .pipe(map(response => {
      return response;
    })
    );
  }
  getEventNotifications(){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.get(this.url + "/api/PushNotifications/EventNotifications", { headers: thisHttpHeader })
    .pipe(map(response => {
      return response;
    })
    );
  }

  saveEventNotifications(dataToSend:any){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json-patch+json', 'Authorization': 'Bearer ' + this.access_token });
    if(dataToSend["id"]!=''){
      return this.http.put(this.url + "/api/PushNotifications/EventNotification",dataToSend,{ headers: thisHttpHeader })
      .pipe(map(response => {
        return response;
      }));
    }
    else {
      return this.http.post(this.url + "/api/PushNotifications/EventNotification",dataToSend,{ headers: thisHttpHeader })
      .pipe(map(response => {
        return response;
      }));
    }
  }

  deleteEventNotifications(dataToSend:any){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json-patch+json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.delete(this.url + "/api/PushNotifications/eventNotification/"+dataToSend.id,{ headers: thisHttpHeader })
    .pipe(map(response => {
      return response;
    })
    );
  }


  getMembersByAffiliateId(affiliateId:string){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.access_token });
    return this.http.get(this.url + "/api/Affiliate/"+affiliateId +"/Users", { headers: thisHttpHeader })
    .pipe(map(response => {
      return response;
    })
    );
  }

  sendEventNotifications(appBundleId:any, dataToSend:any){
    const thisHttpHeader = new HttpHeaders({ 'Content-Type': 'application/json-patch+json', 'AppBundleId': appBundleId ,'Authorization': 'Bearer ' + this.access_token });
    return this.http.post(this.url + "/api/PushNotifications/send",dataToSend,{ headers: thisHttpHeader })
    .pipe(map(response => {
      return response;
    })
    );
  }

}
