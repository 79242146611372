import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './model/User';
import { LoginPage } from './pages/login/login.page';
import { ApiServiceProviderService } from './services/api-service-provider.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  activeIndex=0;
  activePageTitle = 'Dashboard';
  Pages = [
    // {
    //   title: 'Dashboard',
    //   url: '/home',
    //   icon: 'home'
    // },
    {
      title: 'Push Notifications',
      url: '/event',
      icon: 'notifications'
    },
//    {
//      title: 'Aff. Settings',
//      url: '/settings',
//      icon: 'settings'
//    }
  ];

  constructor(public apiService:ApiServiceProviderService, public router:Router) { 
   }

  

  ngOnint() {

  }

  logout(){
    localStorage.clear();
    location.href="/login";
    //this.router.navigate(['/login']);  
  }
  


}
