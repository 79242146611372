export class User {
    id:string;
    email:string;
    name:string;
    image:any;
    managedAffiliates:any

    public User(){
        this.id="";
        this.email="";
        this.name="";
        this.image=null;
        this.managedAffiliates=[];
    }
}
